import axios from "axios";

const {
  VITE_CONTROL_CENTRE_API_URL,
  VITE_PDF_URL,
  VITE_APERTURE_SYSTEM_URL,
  VITE_AUTH_URL,
  VITE_INSTALLER_CENTRE_BASE_URL,
} = import.meta.env;

const control_centre_server = {
  call: (url, params) => {
    return axios.post(`${VITE_CONTROL_CENTRE_API_URL}${url}`, params).catch((error) => {
      return error.response;
    });
  },
  call_await: async (url, params) => {
    return await axios.post(`${VITE_CONTROL_CENTRE_API_URL}${url}`, params).catch((error) => {
      console.error("Axios error", error);
      return error.response;
    });
  },
};

const pdf_server = {
  call: (url, params) => {
    return axios.post(`${VITE_PDF_URL}${url}`, params).catch((error) => {
      return error.response;
    });
  },
};

export default {
  // auth *****************************************************************************/
  authenticateToken: async (token) => {
    const params = {
      token: token,
    };
    const authenticateTokenResult = await control_centre_server.call("/auth/authenticateToken", params);

    let result = {
      status: 401,
      userId: null,
      error: null,
    };

    if (authenticateTokenResult.status === 200) {
      result.status = authenticateTokenResult.status;
      result.userId = authenticateTokenResult.data.userId;
    } else {
      result.error = authenticateTokenResult.response;
    }

    return result;
  },

  getPersonDetails: async (token) => {
    const params = {
      token: token,
    };
    const result = await control_centre_server.call_await("/auth/getPersonDetails", params);
    let response = null;
    if (result && result.data) {
      response = result.data;
      if (response.data?.personData) {
        response = response.data.personData;
        if (Array.isArray(response.data.personData)) {
          response = response.data.personData[0];
        }
      }
    }
    return response;
  },

  // logistics *****************************************************************************/

  getSupplierOrdersWithFailingChecks: async (token, startWeek, endWeek, yearOfStartWeek, yearOfEndWeek) => {
    const params = {
      token,
      startWeek,
      yearOfStartWeek,
      endWeek,
      yearOfEndWeek,
    };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getSupplierOrdersWithFailingChecks", params);
    let supplierOrdersWithFailingChecks = [];
    if (response && response.data && response.data.supplierOrdersResponse) {
      supplierOrdersWithFailingChecks = response.data.supplierOrdersResponse.firstHalf;
      if (response.data.supplierOrdersResponse.secondHalf !== "") {
        supplierOrdersWithFailingChecks = supplierOrdersWithFailingChecks.concat(
          response.data.supplierOrdersResponse.secondHalf,
        );
      }
    }
    return supplierOrdersWithFailingChecks;
  },

  getChecksFromSupplierOrder: async (token, orderId, supplierId, reviewId) => {
    const params = {
      token,
      orderId,
      supplierId,
      reviewId,
    };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }

    const response = await control_centre_server.call("/logistics/getChecksFromSupplierOrder", params);
    let checks = [];
    if (
      response &&
      response.data &&
      response.data.singleSupplierOrderResponse &&
      response.data.singleSupplierOrderResponse.qaReview
    ) {
      checks = response.data.singleSupplierOrderResponse.qaReview;
    }

    return checks;
  },

  getQADataForGraph: async (token) => {
    const params = {
      token,
    };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }

    const response = await control_centre_server.call("/logistics/getQADataForGraph", params);
    return response;
  },

  getReviewData: async (token) => {
    const params = {
      token,
    };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getReviewData", params);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  },

  getSupplierOrderReviews: async (token, orderId, supplierId) => {
    const params = {
      token,
      orderId,
      supplierId,
    };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getSupplierOrderReviews", params);
    return response.data;
  },

  getSupplierOrderRefs: async (token, yearDate) => {
    const params = {
      token,
      yearDate,
    };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getSupplierOrderRefs", params);
    return response.data;
  },

  // *** Logistics Planner ***************************************************************/

  reassign: async (token, driverId, selectedDeliveryNotes) => {
    const params = { token, driverId, selectedDeliveryNotes };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }

    const response = await control_centre_server.call("/logistics/reassignToDriver", params);

    return response;
  },

  getDeliveryNotesDriversAndVehicleEvents: async (token, fromDate) => {
    const params = { token, fromDate };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }

    const deliveryVehicledata = await control_centre_server.call(
      "/logistics/getDeliveryNotesDriversAndVehicleEvents",
      params,
    );

    if (deliveryVehicledata) {
      return deliveryVehicledata.data;
    } else {
      return { logistics: null, vehicleEvents: null };
    }
  },

  getDeliveryNotes: async (token, fromDate) => {
    const params = { token, fromDate };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getDeliveryNotes", params);
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },

  getDeliveryNote: async (token, deliveryNoteId) => {
    const params = { token, deliveryNoteId };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getDeliveryNote", params);
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },

  createDeliveryCollectionNote: async (token, note) => {
    note.email_address = note.deliveryemail;
    delete note.deliveryemail;
    const params = { token, note };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/createDeliveryCollectionNote", params);
    return response;
  },

  updateDeliveryCollectionNote: async (token, note) => {
    note.email_address = note.deliveryemail;
    delete note.deliveryemail;
    const params = { token, note };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/updateDeliveryCollectionNote", params);
    return response;
  },

  generateLogisticsPdf: async (token, date, driver, driverId) => {
    const params = { token, date, driver, driverId, responseType: "blob" };
    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await pdf_server.call("/pdf/logistics", params);
    return response;
  },

  sendOrdersToMaxOptra: async (token, orders, driverId) => {
    const params = { token, orders, driverId };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/sendOrdersToMaxOptra", params);
    return response.data;
  },

  getOrderExecution: async (token, deliveryNote) => {
    const params = { token, deliveryNote };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/execution", params);
    return response.data;
  },

  // *** Manage Vehicles ***************************************************************/

  getDeliveryVehicleData: async (token) => {
    const params = { token };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getDeliveryVehicleData", params);
    return response.data;
  },

  insertDeliveryVehicle: async (token, vehicleInfo) => {
    const params = { token, vehicleInfo };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/insertDeliveryVehicle", params);
    return response.data;
  },

  updateDeliveryVehicle: async (token, vehicleInfo) => {
    const params = { token, vehicleInfo };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/updateDeliveryVehicle", params);
    return response.data;
  },

  getDeliveryVehicleEvents: async (token, fromDate) => {
    const params = { token, fromDate };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/getDeliveryVehicleEvents", params);
    return response.data;
  },

  insertDeliveryVehicleEvent: async (token, vehicleEvent) => {
    const params = { token, vehicleEvent };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/insertDeliveryVehicleEvent", params);
    return response.data;
  },

  updateDeliveryVehicleEvent: async (token, vehicleEvent) => {
    const params = { token, vehicleEvent };

    if (VITE_APERTURE_SYSTEM_URL) {
      params.devAPIServer = VITE_APERTURE_SYSTEM_URL;
    }
    const response = await control_centre_server.call("/logistics/updateDeliveryVehicleEvent", params);
    return response.data;
  },

  // *** Staff ***************************************************************/

  getStaffDetails: async (token) => {
    const params = { token };

    if (VITE_AUTH_URL) {
      params.devAuthServer = VITE_AUTH_URL;
    }
    const response = await control_centre_server.call("/staff/getStaffDetails", params);
    return response.data;
  },

  saveStaffDetails: async (token, details, id) => {
    const params = { token, details, id };

    if (VITE_AUTH_URL) {
      params.devAuthServer = VITE_AUTH_URL;
    }
    const response = await control_centre_server.call("/staff/saveStaffDetails", params);
    return response.data;
  },

  getUserPermissions: async (token) => {
    const params = { token };

    if (VITE_AUTH_URL) {
      params.devAuthServer = VITE_AUTH_URL;
    }
    const response = await control_centre_server.call_await("/auth/getUserPermissions", params);
    return response.data;
  },

  getSystemPermissions: async (token) => {
    const params = { token };
    if (VITE_AUTH_URL) {
      params.devAuthServer = VITE_AUTH_URL;
    }

    const response = await control_centre_server.call_await("/auth/getSystemPermissions", params);
    return response.data;
  },
};
