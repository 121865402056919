import React from "react";

const LogisticsPlanner = React.lazy(() => import("./logistics/logisticsPlanner/LogisticsPlanner"));
const DeliveryCollectionForm = React.lazy(() => import("./logistics/logisticsPlanner/DeliveryCollectionForm"));
const ManageVehicles = React.lazy(() => import("./logistics/logisticsPlanner/ManageVehicles"));
const GraphsSentToSupplierYtd = React.lazy(() => import("./graphs/sentToSupplier/ytd"));
const GraphsSentToSupplierMonthly = React.lazy(() => import("./graphs/sentToSupplier/monthly"));
const GraphsProcessedByProcessor = React.lazy(() => import("./graphs/processedByProcessor"));

const DaleMap = React.lazy(() => import("./daleAnalytics/map"));

const Orders = React.lazy(() => import("./orders"));
const Order = React.lazy(() => import("./orders/Order"));

const Enquiries = React.lazy(() => import("./enquiries"));

const PDFEditor = React.lazy(() => import("./pdf/edit"));

// const ServiceRequests = React.lazy(() => import("./serviceRequests"));
// const ServiceRequest = React.lazy(() => import("./serviceRequests/ServiceRequest"));
const CalendarLinks = React.lazy(() => import("./serviceRequests/CalendarLinks"));
const SRSiteVisit = React.lazy(() => import("./serviceRequests/SiteVisit"));
const SiteVisitMap = React.lazy(() => import("./serviceRequests/SiteVisitMap"));
const SRCalendar = React.lazy(() => import("./serviceRequests/Calendar"));
const SRCalendarEvent = React.lazy(() => import("./serviceRequests/CalendarEvent"));
const SROutstandingSiteVisit = React.lazy(() => import("./serviceRequests/OutstandingSiteVisits"));

const routes = [
  { path: "/", element: () => "Welcome to the new PWG control centre!" },
  { path: "/daleAnalytics/map", element: DaleMap },
  { path: "/enquiries", element: Enquiries },
  { path: "/serviceRequests/siteVisitMap", element: SiteVisitMap, title: "Site Visit Map" },
  { path: "/serviceRequests/outstandingSiteVisits", element: SROutstandingSiteVisit, title: "Site Visits" },
  { path: "/serviceRequests/calendar", element: SRCalendar, title: "Calendar" },
  { path: "/serviceRequests/addCalendarEvent", element: SRCalendarEvent, title: "New Calendar Event" },
  { path: "/serviceRequests/calendarEvents/:calendarEventId", element: SRCalendarEvent, title: "Edit Calendar Event" },
  { path: "/serviceRequests/:serviceRequestId/addSiteVisit", element: SRSiteVisit, title: "New Site Visit" },
  { path: "/serviceRequests/:serviceRequestId/siteVisit/:siteVisitId", element: SRSiteVisit, title: "Edit Site Visit" },
  { path: "/serviceRequests/calendarLinks", element: CalendarLinks, title: "Calendar Links" },
  { path: "/orders", element: Orders },
  { path: "/orders/:orderId", element: Order },
  { path: "/logistics", element: LogisticsPlanner },
  { path: "/logistics/planner", element: LogisticsPlanner },
  { path: "/logistics/deliveryCollectionForm/:delivery_note_id", element: DeliveryCollectionForm },
  { path: "/logistics/deliveryCollectionForm", element: DeliveryCollectionForm },
  { path: "/logistics/manageVehicles", element: ManageVehicles },
  { path: "/graphs", element: GraphsSentToSupplierMonthly },
  { path: "/graphs/sentToSupplier/monthly", element: GraphsSentToSupplierMonthly },
  { path: "/graphs/sentToSupplier/ytd", element: GraphsSentToSupplierYtd },
  { path: "/graphs/processedByProcessor", element: GraphsProcessedByProcessor },
  { path: "/pdf/edit/:transactionType/:transactionId/:identifierId", element: PDFEditor },
  { path: "*", element: () => "Not Found" },
];

export default routes;
