import React, { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import ThemeToggle from "../themeToggle";
import { ThemeContext } from "../../contexts/themeContext";
import { NIGHT_MODE_KEY } from "../../constants";
import { icons } from "../../assets/icons/icons";

const { VITE_DASHBOARD_BASE_URL } = import.meta.env;

const Header = ({ userDetails, logout, isCollapsedSideMenu }) => {
  const { themeKey } = useContext(ThemeContext);
  const isNightMode = themeKey === NIGHT_MODE_KEY;

  const getInitials = () => {
    let firstName = "";
    let lastName = "";

    if (userDetails?.forename || userDetails?.surname) {
      // New personDetails table
      firstName = userDetails.forename || "";
      lastName = userDetails.surname || "";
    } else if (userDetails?.personData && userDetails?.personData.length > 0) {
      // Old staff table
      firstName = userDetails.personData[0].staff_first_name || "";
      lastName = userDetails.personData[0].staff_last_name || "";
    }

    // Safely get the first character
    const getFirstChar = (value) => {
      return typeof value === "string" ? value.charAt(0) : "";
    };

    return `${getFirstChar(firstName)}${getFirstChar(lastName)}`;
  };

  const initials = getInitials();

  return (
    <div className="navbar w-full border-b-2">
      <div className={`flex-none ${isCollapsedSideMenu ? "" : "lg:hidden"}`}>
        <label htmlFor="drawer-id" className="btn btn-square btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block h-6 w-6 stroke-current"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>
      </div>
      <div className="navbar-start">
        <Link to="/" className="navbar-brand">
          <img
            className="mr-2"
            src={isNightMode ? icons.pwgHeaderLogoDark : icons.pwgHeaderLogo}
            width={52}
            alt={"pwg logo"}
          />
        </Link>
        <div className="mr-2 hidden text-xl md:block">PWG CONTROL CENTRE</div>
      </div>
      <div className="navbar-center"></div>
      <div className="navbar-end flex items-center">
        <button className="btn btn-sm hidden lg:block">
          <a
            href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_pwg_dashboard.cgi`}
            target="_blank"
            rel="noopener noreferrer"
          >
            PWG
          </a>
        </button>
        <button className="btn btn-sm mx-2 hidden lg:block">
          <a
            href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_dl_dashboard.cgi`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Dale
          </a>
        </button>
        <button className="btn btn-sm hidden lg:block">
          <a href={`${VITE_DASHBOARD_BASE_URL}/control/control_center.cgi`} target="_blank" rel="noopener noreferrer">
            TW
          </a>
        </button>

        <div className="mx-4 hidden text-sm lg:block">
          {moment().format("dddd Do MMMM YYYY")}, Week {moment().format("W")}
        </div>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="avatar placeholder btn btn-circle btn-outline">
            <span className="">{initials}</span>
          </label>
          <ul tabIndex={0} className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow">
            <li>
              <ThemeToggle />
            </li>
            <li>
              <a onClick={logout}>Log out</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
